import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Sale',
    data() {
        return {
            categories: []
        };
    },
    mounted() {
        fetch('scripts/getSaleData.php')
            .then(response => response.json())
            .then(data => this.categories = data.categories);
    }
});
