import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Catalog',
    data() {
        return {
            categories: [],
            expanded: null,
            subexpanded: null
        };
    },
    computed: {
        category() {
            if (this.expanded == null)
                return false;
            if (this.subexpanded == null)
                return this.categories[this.expanded];
            else
                return this.categories[this.expanded].subcategories[this.subexpanded];
        },
        imageSrcBefore() {
            let result = '';
            if (this.$route.params.id != undefined)
                result += '../';
            if (this.$route.params.subid != undefined)
                result += '../';
            return result;
        }
    },
    methods: {
        expand(category, subcategory) {
            this.expanded = category;
            this.subexpanded = subcategory;
            if (window.innerWidth <= 800) {
                let content = document.querySelector('.catalog__content');
                let top = content.getBoundingClientRect().top;
                window.scroll(0, top);
            }
        }
    },
    mounted() {
        var id, subid, fetchString = '';
        if (this.$route.params.id != undefined) {
            id = this.$route.params.id;
            fetchString += '../';
        }
        if (this.$route.params.subid != undefined) {
            subid = this.$route.params.subid;
            fetchString += '../';
        }
        fetchString += 'scripts/getCatalogData.php';
        fetch(fetchString)
            .then(response => response.json())
            .then(data => {
            this.categories = data.categories;
            if (this.$route.params.id != undefined) {
                let category = this.categories.findIndex(item => item.id == parseInt(id));
                let subcategory = null;
                if (this.$route.params.subid != undefined)
                    subcategory = this.categories[category].subcategories.findIndex(item => item.id == parseInt(subid));
                this.expand(category, subcategory);
            }
            else
                this.expanded = 0;
        });
    }
});
