import { defineComponent } from 'vue';
export default defineComponent({
    data() {
        return {
            pages: [
                { name: 'Katalog', url: '/katalog', route: 'Catalog' },
                { name: 'Wyprzedaże', url: '/wyprzedaze', route: 'Sale' },
                { name: 'Nowości', url: '/nowosci', route: 'News' },
                { name: 'Blog', url: '/blog', route: 'Blog' }
            ],
            mobileMenu: false
        };
    },
    watch: {
        $route() {
            if (this.mobileMenu)
                this.mobileMenu = false;
        }
    }
});
