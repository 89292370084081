import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Catalog',
    data() {
        return {
            pages: [],
            currentPage: 0
        };
    },
    methods: {
        parseDate(date, newLine) {
            let elements = date.split('-');
            let months = [
                'sierpień',
                'luty',
                'marzec',
                'kwiecień',
                'maj',
                'czerwiec',
                'lipiec',
                'sierpień',
                'wrzesień',
                'październik',
                'listopad',
                'grudzień'
            ];
            let result = `${parseInt(elements[2])} ${months[parseInt(elements[1])]}`;
            if (newLine)
                result += '<br>';
            return `${result} ${elements[0]}`;
        },
        changePage(page) {
            if (this.pages[page] == undefined || this.pages[page] == null || this.pages[page].length == 0) {
                fetch('scripts/getNewsData.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        count: 6,
                        skip: (6 * page)
                    })
                })
                    .then(response => response.json())
                    .then(data => {
                    this.pages[page] = data.items;
                });
            }
            window.scrollTo(0, 0);
            this.currentPage = page;
        }
    },
    mounted() {
        fetch('scripts/getNewsData.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                count: 6,
                skip: 0
            })
        })
            .then(response => response.json())
            .then(data => {
            let numberOfPosts = data.rowCount;
            let pageIndex = 0;
            while (numberOfPosts > 0) {
                this.pages[pageIndex] = [];
                numberOfPosts -= 6;
                pageIndex++;
            }
            this.pages[0] = data.items;
        });
    }
});
