import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Contact',
    data() {
        return {
            contactData: {},
            formName: '',
            formEmail: '',
            formSubject: '',
            formMessage: '',
            messageSend: false
        };
    },
    computed: {
        emailValid() {
            if (this.formEmail == '')
                return null;
            let expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (this.formEmail.toLowerCase().match(expression))
                return true;
            return false;
        }
    },
    methods: {
        submit() {
            if (this.messageSend)
                return;
            if (this.formName == '' || this.formEmail == '' || this.formMessage == '')
                return alert('Pola imię, email i wiadomość nie mogą być puste');
            if (!this.emailValid)
                return alert('Nieprawidłowy adres email');
            fetch('scripts/contactMessage.php', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    name: this.formName,
                    email: this.formEmail,
                    subject: this.formSubject,
                    message: this.formMessage
                })
            })
                .then(response => response.json())
                .then(data => {
                if (data.error)
                    return alert('Wystąpił błąd podczas wysyłania twojej wiadmości');
                alert('Twoja wiadomość została wysłana');
                this.messageSend = true;
            });
        }
    },
    mounted() {
        fetch('scripts/contactData.json')
            .then(response => response.json())
            .then(data => this.contactData = data);
    }
});
