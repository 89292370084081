import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Catalog from '@/views/Catalog.vue';
import Sale from '@/views/Sale.vue';
import News from '@/views/News.vue';
import Blog from '@/views/Blog.vue';
import Contact from '@/views/Contact.vue';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    }, {
        path: '/katalog',
        name: 'Catalog',
        component: Catalog
    }, {
        path: '/katalog/:id',
        name: 'CatalogId',
        component: Catalog
    }, {
        path: '/katalog/:id/:subid',
        name: 'CatalogSubId',
        component: Catalog
    }, {
        path: '/wyprzedaze',
        name: 'Sale',
        component: Sale
    }, {
        path: '/nowosci',
        name: 'News',
        component: News
    }, {
        path: '/blog',
        name: 'Blog',
        component: Blog
    }, {
        path: '/kontakt',
        name: 'Contact',
        component: Contact
    }
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});
export default router;
