import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Home',
    data() {
        return {
            news: [],
            companies: [
                {
                    href: 'https://www.fakro.pl/',
                    image: 'fakro'
                }, {
                    href: 'https://www.adams.com.pl/',
                    image: 'adams'
                }, {
                    href: 'https://www.pol-skone.pl/',
                    image: 'pol-skone'
                }, {
                    href: 'https://www.wisniowski.pl/',
                    image: 'wisniowski'
                }, {
                    href: 'https://www.porta.com.pl/',
                    image: 'porta'
                }, {
                    href: 'https://dre.pl/',
                    image: 'dre'
                }, {
                    href: 'https://agmar.biz.pl/',
                    image: 'agmar'
                }, {
                    href: 'https://www.doorsy.pl/',
                    image: 'doorsy'
                }, {
                    href: 'https://www.centurion.com.pl/',
                    image: 'centurion'
                }, {
                    href: 'https://alston.net.pl/',
                    image: 'alston'
                }, {
                    href: 'https://intenso-doors.pl/',
                    image: 'intenso'
                }, {
                    href: 'https://www.delta.net.pl/',
                    image: 'delta'
                }
            ]
        };
    },
    mounted() {
        fetch(`scripts/getNewsData.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                count: 4,
                skip: 0
            })
        })
            .then(response => response.json())
            .then(data => this.news = data.items);
    }
});
